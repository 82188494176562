/*===================================
=                                   =
=  - !Hello There                   =
=  - this is Your working space     =
=  - here you can customize phenix  =
=  - to your app ui design needs    =
=  - Good Luck :)                   =
=                                   =
===================================*/

/*====> Import Phenix <====*/
import Phenix from '.';

//======> D.O.M is Ready [for you] <=======//
Phenix(document).ready((event:any) => { /*--> Your Code <--*/});
 //====> Multimedia <====//
 Phenix('.px-media').multimedia();
 //====> Popups <====//
 Phenix('.px-modal').popup();

//  Off-Canvas Menu
let sidenav=document.getElementById("mySidenav");
let sidecontent=document.getElementById("side-content");
let body=document.querySelector("body");
let links=document.querySelectorAll(".px-item");
links.forEach((e)=>{
    e.addEventListener("click",()=>{
        links.forEach((itm)=>{
            itm.classList.remove("active");
            itm.querySelector("img").classList.remove("active-itm")
        })
       e.classList.add("active");  
    })
    
})
// category
let category= document.querySelectorAll(".category");
category.forEach(itm=>{
    itm.addEventListener("mouseover",()=>{
        var link = document.querySelector(`[href='#${itm.id}']`);
        links.forEach((itm)=>{
            itm.classList.remove("active")
            itm.querySelector("img").classList.remove("active-itm")
          
        })
       let image=link.querySelector("img");
       image.classList.add("active-itm");
    
       
           console.log(image);
           
             
    })
})


function openNav() {
    sidenav.style.height = "100%";
    sidecontent.style.top = "470px";
    sidenav.style.backgroundColor = "rgba(255,255,255,0.6)" ;
    body.style.overflow="hidden";
}
if(sidecontent){
sidecontent.addEventListener("click",function(e){e.stopPropagation()})
}
function closeNav() {
    sidenav.style.height = "0%";
    sidecontent.style.top = "100%";
    sidenav.style.backgroundColor = "white";
    body.style.overflow="auto";
  }
 let closebtn=document.querySelector(".closebtn");
 if(closebtn){  closebtn.addEventListener("click",closeNav);}
 let closebtn2= document.querySelector("#closebtn")
  if(closebtn2)
  {  closebtn2.addEventListener("click",closeNav);}

 let openbtn= document.querySelector(".openbtn")
if(openbtn) {
    openbtn.addEventListener("click",openNav);
} 
  //====> Popups <====//
Phenix('.px-modal').popup({
    type : 'modal',    //===> the type of the popup as modal or lightbox
    hash_url : false,  //===> Show the Modal if called from url
    backdrop : true,   //===> Enable/Disable Close by Backdrop
    onload   : false,  //===> Show on Page Load Required for 'timeout';
    showon   : false,  //===> Show the Modal when the '#target' is in View-Point
    timeout  : 0,      //===> Delay the Modal from showing work for [showon, onload]
});

// copy btn
let copybtn= document.getElementById("copy-btn");
if(copybtn) {
    copybtn.addEventListener("click",()=>{
        var copyText = document.getElementById("myInput") as HTMLInputElement
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
      })
}
 //===>slider<====//
 Phenix('.px-slider').slider();